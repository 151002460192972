import React from 'react';

import FeaturedImage from '../FeaturedImage';
import H1 from '../H1';
import Wrapper from './Wrapper';
import Link from './Link';
import Date from './Date';
import ContinueReading from './ContinueReading';
import Article from '../Article';

import styled from 'styled-components';

const Preview = styled(Article)`
  color: #666d71;
  margin: 50px 0 0 0;
  text-align: center;
`;


function Summary({ date, title, excerpt, slug, image }) {
  return (
    <Wrapper>
      {image &&
        <Link to={slug}>
          <FeaturedImage sizes={image.childImageSharp.sizes} />
        </Link>
      }
      <H1><Link to={slug}>{title}</Link></H1>
      <Date>{date}</Date>
      <Preview dangerouslySetInnerHTML={{ __html: excerpt }} />
      <ContinueReading to={slug}>Continue Reading &rarr;</ContinueReading>
    </Wrapper>
  );
}

export default Summary;
